import React from "react"
import {
  container,
  contact,
  join,
  arrow,
  submit,
  description,
  descriptionContainer,
  title,
} from "../styles/open-position.module.css"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

const OpenPositions = ({ titleString, descriptionString, creativeOffer }) => {
  const [t] = useTranslation()
  return (
    <div className={container}>
      <div className={descriptionContainer}>
        <h2 className={title}>{titleString}</h2>
        <p className={description}>{descriptionString}</p>
      </div>
      <div className={contact}>
        <span className={arrow}>{"\u2192"}</span>
        <h3 className={join}>{creativeOffer}</h3>
        <Link to="/contact" className={submit}>
          {t("Contact")}
        </Link>
      </div>
    </div>
  )
}

export default OpenPositions
