import React, { useState } from "react"
import Page from "../components/Page"
import JoinUsContent from "../components/JoinUsContent"
import JoinUsMenu from "../components/JoinUsMenu"
import Seo from "../SEO"

const JoinUs = () => {
  const [page, setPage] = useState(0)

  const switchPage = newPage => {
    setPage(newPage)
  }

  return (
    <>
      <Page>
        <Seo
          title="Engagier dich sozial mit einem Job bei DeBaCode"
          description="Werde Teil eines multinationalen Teams und bekämpfe mit uns gemeinsam die Jugendarbeitslosigkeit in Bosnien"
          keywords={[
            "Job",
            "Ehrenamtlich",
            "Gemeinnützig",
            "Sozial",
            "IT-Management",
            "Entwicklung",
          ]}
        ></Seo>
        <JoinUsMenu switchPage={switchPage} />
        {page === 0 ? (
          <JoinUsContent country="Germany" />
        ) : (
          <JoinUsContent country="Bosnia" />
        )}
      </Page>
    </>
  )
}

export default JoinUs
