import React from "react"
import { useTranslation } from "react-i18next"
import {
  container,
  wrapper,
  deutschland,
  title,
  de,
  ba,
  equal,
  subscript,
  ourBelovedBosnia,
  mitmachen,
  pageSwitcher,
} from "../styles/join-us-menu.module.css"
import Layout from "./Layout"

const JoinUsMenu = ({ switchPage }) => {
  const [t] = useTranslation()

  return (
    <div className={container}>
      <div className={wrapper}>
        <p className={deutschland}>Deutschland</p>
        <h1 className={title}>
          <span className={de}>DE</span>
          <span className={ba}>BA</span>
          <span className={equal}> =</span>
          <sub className={subscript}> [bosn.]</sub> Auf geht's!
        </h1>
        <p className={ourBelovedBosnia}>
          Bosnien und
          <br />
          Herzegowina
        </p>
      </div>
      <Layout>
        <h1 className={mitmachen}>{t("Participate")}</h1>
        <div className={pageSwitcher}>
          <button
            onClick={() => {
              switchPage(0)
            }}
          >
            {t("In Germany")} {"\u2192"}
          </button>
          <button
            onClick={() => {
              switchPage(1)
            }}
          >
            {t("In Bosnia")} {"\u2192"}
          </button>
        </div>
      </Layout>
    </div>
  )
}

export default JoinUsMenu
