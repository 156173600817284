import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { useTranslation } from "react-i18next"

import {
  titleStyle,
  list,
  paragraph,
  contact,
  openPositionsContainer,
} from ".//JoinUsContent.module.css"

import Layout from "./Layout"
import OpenPositions from "./OpenPosition"
import Offer from "./Offer"
import HeroImage from "./HeroImage"
import { Carousel } from "react-responsive-carousel"
import "../styles/carousel.min.css"

export default function JoinUsContent({ country }) {
  const { t, i18n } = useTranslation()
  return (
    <StaticQuery
      query={graphql`
        {
          de_content_germany: contentfulJoinUsPage(
            identifier: { eq: "join-us-germany" }
            node_locale: { eq: "de-DE" }
          ) {
            ...JoinUsContent
          }
          ba_content_germany: contentfulJoinUsPage(
            identifier: { eq: "join-us-germany" }
            node_locale: { eq: "bs-BA" }
          ) {
            ...JoinUsContent
          }
          de_content_bosnia: contentfulJoinUsPage(
            identifier: { eq: "join-us-bosnia" }
            node_locale: { eq: "de-DE" }
          ) {
            ...JoinUsContent
          }
          ba_content_bosnia: contentfulJoinUsPage(
            identifier: { eq: "join-us-bosnia" }
            node_locale: { eq: "bs-BA" }
          ) {
            ...JoinUsContent
          }
        }
      `}
      render={data => {
        const content =
          country === "Germany"
            ? data[i18n.language + "_content_germany"]
            : data[i18n.language + "_content_bosnia"]

        return (
          <div>
            <HeroImage
              altText="Team DeBaCode"
              imageList={content.heroImageList}
            />
            <Layout>
              <h1 className={titleStyle}>{content.benefitsTitle}</h1>
              <div>
                {content.benefits.map((benefit, i) => (
                  <Offer
                    key={i}
                    alternate={i % 2 === 1}
                    image={benefit.icon}
                    text={benefit.description}
                  />
                ))}
              </div>
              <h1 className={titleStyle}>{content.requirementsTitle}</h1>
              <ul className={list}>
                {content.requirements.map((requirement, i) => (
                  <li key={i}>
                    <p className={paragraph}>{requirement}</p>
                  </li>
                ))}
              </ul>
              {content.openPositions && (
                <div className={openPositionsContainer}>
                  <h1 className={titleStyle}>{content.openPositionsTitle}</h1>
                  <Carousel
                    showArrows={true}
                    showStatus={false}
                    autoPlay={true}
                    infiniteLoop={true}
                    centerMode={false}
                    dynamicHeight={false}
                    transitionTime={1000}
                    interval={4000}
                    showThumbs={false}
                  >
                    {content.openPositions.map((position, i) => (
                      <OpenPositions
                        key={i}
                        titleString={position.title}
                        descriptionString={position.description.description}
                        creativeOffer={position.callToAction}
                      />
                    ))}
                  </Carousel>
                </div>
              )}
            </Layout>
            <div>
              {content.teamImageList && (
                <HeroImage
                  imageList={content.teamImageList}
                  altText="A picture of the Enactus KIT team"
                />
              )}
              {content.howToJoinTitle && content.howToJoinText && (
                <Layout>
                  <h1 className={titleStyle}>{content.howToJoinTitle}</h1>
                  <p className={paragraph}>
                    {content.howToJoinText.howToJoinText}
                  </p>
                  <Link className={contact} to="/contact">
                    {t("Kontakt")}
                  </Link>
                </Layout>
              )}
            </div>
          </div>
        )
      }}
    />
  )
}
