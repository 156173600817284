import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import {
  container,
  alternateContainer,
  alternateIcon,
  subtitle,
} from "../styles/offer.module.css"

const Offer = ({ image, text, alternate }) => {
  return (
    <div
      className={`${container}` + (alternate ? ` ${alternateContainer}` : ``)}
    >
      <GatsbyImage
        alt=""
        image={image.gatsbyImageData}
        className={alternate ? `${alternateIcon}` : ``}
      />
      <h2 className={subtitle}>{text}</h2>
    </div>
  )
}

export default Offer
