import React from "react"
import { image } from "./HeroImage.module.css"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"

export default function HeroImage({ imageList, altText }) {
  var heroImage = null
  if (imageList.length > 1) {
    heroImage = withArtDirection(getImage(imageList[0]), [
      {
        media: "(max-width: 900px)",
        image: getImage(imageList[1]),
      },
    ])
  } else if ((imageList.length = 1)) {
    heroImage = getImage(imageList[0])
  } else {
    heroImage = getImage(imageList)
  }

  return <GatsbyImage alt={altText} image={heroImage} className={image} />
}
